import {serverTimestamp } from 'firebase/firestore';
import {addFireStore} from "./index";

export function logAction(userId, what, action,  detail, value, others) {

  const log = {
    user:userId ?? 'Unknown',
    domain:'RESERVATION',
    what,
    action,
    detail,
    value: value ?? null,
    others: others ?? {},
    timestamp: serverTimestamp()
  }

  addFireStore('action', log).catch((e)=>{
    console.log('Cannot log action', e);
  });
}



// export type ACTION = 'CREATE RESERVATION'
//     | 'UPDATE RESERVATION'
//     | 'CANCEL RESERVATION'
//     | 'RECOVER RESERVATION'
//     | 'NOSHOW RESERVATION'
//     |  'RETRIEVE RESERVATION'
//     |  'DOWNLOAD RESERVATION'
//     | 'STAR RESERVATION'
//     | 'CHECK RESERVATION'
//     | 'CREATE PRODUCT'
//     | 'UPDATE PRODUCT'
//     | 'RECOVER PRODUCT'
//     | 'DELETE PRODUCT'
//     | 'DELETE EXCEPTION'
//     | 'CHANGE GUIDE'
//     | 'UPDATE TEAM MEMO'
//     | 'UPDATE OPERATION MEMO'
//     | 'UPDATE RESERVATIONS NOTE'
//     | 'CHANGE RESERVATION TEAM'
//     | 'CLEAR DISPATCH'
//     | 'UPDATE DISPATCH'
//     | 'UPDATE DISPATCH PHRASE'
// ;
//
// export type ACTION_WHAT = string
//
// export type ACTION_DOMAIN = 'RESERVATION'
//     | 'PRODUCT'
//     | 'OPERATION'
//     | 'EXCEPTION'
//
//
// export function logAction(domain: ACTION_DOMAIN, action: ACTION, what: ACTION_WHAT, detail: string, value?: any, others?: {
//   [key: string]: any
// }) {
//   const user = getCurrentUser()?.uid ?? 'unknown';
//   const log = {
//     user,
//     domain,
//     what,
//     action,
//     detail,
//     value: value ?? null,
//     others: others ?? {},
//     timestamp: serverTimestamp()
//   }
//   addDoc(collectionWithBaseFireStore('action'), log).catch((e) => {
//     console.log('Cannot log action', e)
//   })
// }
