import React, {useCallback, useState, useEffect, useRef} from 'react';
import {useNavigate} from "react-router-dom";

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ButtonBase from '@mui/material/ButtonBase';
import Chip from '@mui/material/Chip';
import Checkbox from '@mui/material/Checkbox';
import Collapse from '@mui/material/Collapse';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ListItemButton from '@mui/material/ListItemButton';
import Typography from '@mui/material/Typography';
import QrIcon from '@mui/icons-material/QrCode';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import ChatIcon from '@mui/icons-material/Chat';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import CopyIcon from '@mui/icons-material/ContentCopy';


import copy from '../../utils/copy';

import {callFunction, read, transaction, updateFireStore} from '../../controller/firebase';
import {useGuideContext} from '../../controller/context/GuideContext';
import {useDateContext} from '../../controller/context/DateContext';
import Stack from "@mui/material/Stack";
import QRCode from "react-qr-code";

import html2canvas from 'html2canvas';
import sanitizeFileName from "../../utils/senitizeFileName";
import TextField from "@mui/material/TextField";
import CircularProgress from "@mui/material/CircularProgress";
import useFRead from "../../controller/firebase/hook/useFRead";
import {logAction} from "../../controller/firebase/logAction";
import Divider from "@mui/material/Divider";


export default function ReservationItem({
                                          productId,
                                          teamId,
                                          date,
                                          reservation,
                                          live,
                                          isMyTeam,
                                          belongMyTour,
                                          onTimestamp
                                        }) {
  const navigate = useNavigate();
  const qrRef = useRef(null);
  const {isToday, dashFormat} = useDateContext();
  const {guide} = useGuideContext();
  // const [checked, setChecked] = useState(reservation.check);
  // const [noShow, setNoShow] = useState(reservation.noShow);
  const [open, setOpen] = useState(false);
  const [expand, setExpand] = useState(false);
  const [qr, setQr] = useState(false);
  const [loadingChat, setLoadingChat] = useState(false);
  const [isCanceling, setIsCanceling] = useState(false);
  const {data: product} = useFRead(`/product/${productId}`);
  const pickups = Object.values(product?.chat?.pickup ?? {}).filter(p => p.use).sort(({order: a = 99}, {order: b = 99}) => a > b ? 1 : -1);
  const isEditable = guide.level <= 1 && live;
  const [updates, setUpdates] = useState({});
  const [isUpdating, setIsUpdating] = useState(false);
  const isCheckable = live;
  const isControlable = live && (guide?.admin || (belongMyTour && isToday));
  const isDeletable = live && (guide?.outsource) && guide.managingProducts?.includes(productId) && reservation.id.startsWith('KTOS');

  const noShow = reservation.noShow;


  const handleUpdateReservation = (e) => {
    const property = e.target.name;
    const type = e.target.type;
    const value = type === 'number' ? Number(e.target.value) : e.target.value;
    setUpdates((prev) => {
      if (!prev) return {[property]: value};
      return ({...prev, [property]: value});
    })
  }

  const handleSaveUpdates = () => {
    const timestamp = Date.now();
    if (!guide) return;

    const cleanedUpdateTuples = Object.entries(updates ?? {}).filter(([_, value]) => value !== undefined);
    if (cleanedUpdateTuples.length === 0) return;
    const cleanedUpdates = Object.fromEntries(cleanedUpdateTuples);
    setIsUpdating(true);
    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        Object.entries(updates ?? {}).forEach(([prop, value]) => {
          if (value !== undefined) {
            reservation[prop] = value;
          }
        })
      }
      return reservation;
    })
        .then(() => {
          // setChecked(value);
          onTimestamp(timestamp);
          return updateFireStore('reservation', reservation.id, cleanedUpdates);
        })
        .then(() => {
          logAction(guide.id, reservation.path, 'UPDATE RESERVATION', 'Update Reservation On CS(GUIDE WEB)', cleanedUpdates, {});
          setUpdates({});
        })
        .catch(e => {
          alert('Fail Update Reservations');
        })
        .finally(() => {
          setIsUpdating(false)
        });

  }

  const handleCancel = ()=>{
    const timestamp = Date.now();
    const canceledAt = new Date().toString();
    setIsUpdating(true);
    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        reservation.canceledAt = canceledAt;
      }
      return reservation;
    })
        .then(() => {
          // setChecked(value);
          onTimestamp(timestamp);
          return updateFireStore('reservation', reservation.id, {canceledAt});
        })
        .then(() => {
          logAction(guide.id, reservation.path, 'CANCEL RESERVATION', 'Cancel Reservation On CS(GUIDE WEB)', {canceledAt}, {});
          setUpdates({});
        })
        .catch(e => {
          alert('Fail Cancel Reservations');
        })
        .finally(() => {
          setIsUpdating(false)
        });
  }


  const handleLiveChecked = (e, value) => {
    // setChecked(value);
    // todo use security regulation
    const timestamp = Date.now();
    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        reservation.check = value;
        reservation.timestamp = timestamp
      }
      return reservation;
    }).then(() => {
      // setChecked(value);
      onTimestamp(timestamp);
      return updateFireStore('reservation', reservation.id, {check: value});
    })
        .catch(e => {
          alert('Please turn on sync when you check reservation.');
        });


  }
  const handleClickMessenger = (e) => {
    copy(reservation.messenger);
    window?.prompt('Messenger copied!', reservation.messenger);
  }

  const handleClickQR = (e) => {
    setQr((qr) => !qr);
  }

  const handleToggleNoShow = (e) => {
    const newNoShow = !noShow;
    // setNoShow(newNoShow);

    transaction(`${reservation.path}`, (reservation) => {
      if (reservation) {
        reservation.noShow = newNoShow;
      }
      return reservation;
    })
        .then(() => {
          return updateFireStore('reservation', reservation.id, {noShow: newNoShow})
        })
        .catch(e => {
          console.log(e);
          alert('Please turn on sync when you check reservation.');
        });

  };

  const handleDelete = () => {
    setIsCanceling(true);
    callFunction('cancelOutsourcedReservation', {reservationId: reservation.id})
        .then((r) => {
          console.log(r);
          alert('예약이 취소되었습니다.');
        })
        .catch((e) => {
          alert('예약을 취소할 수 없습니다.')
        })
        .finally(() => {
          setIsCanceling(false);
        })
  }

  const handleToggleOpen = useCallback((e) => {
    setOpen((v) => !v);
  }, [reservation.path]);

  const handleToggleExpand = useCallback((e) => {
    setExpand((v) => !v);
  }, [reservation.path]);

  const handleCopyGen = useCallback((text) => (e) => {
    copy(text);
  }, [reservation.path]);

  const stopPropagation = useCallback((e,) => {
    e.stopPropagation();
  }, []);

  const downloadQR = () => {
    const qr = qrRef.current;
    if (!qr) return;

    html2canvas(qr).then((canvas) => {
      // PNG 파일 다운로드
      const pngFile = canvas.toDataURL("image/png");
      const downloadLink = document.createElement('a');
      downloadLink.href = pngFile;
      downloadLink.download = sanitizeFileName(`${reservation.clientName.replace(/\([^)]*\)/g, "")}-${reservation.agencyCode}.png`);
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    });
  }


  const handleChatDirect = async (e) => {
    setLoadingChat(true);
    try {
      const category = "CLIENT-GUIDE";
      const cId = `${category}:${productId}:${date}:${teamId}:${reservation.id}`
      const title = `${date}:${productId.split("_").pop()}:${reservation.clientName}`
      //
      const chatParams = {
        category,
        participant: {
          id: reservation.id,
          name: reservation.clientName,
          nameEn: "Client",
          type: "client",
          agent: {
            device: '',
            browser: '',
          },
        },
        cId,
        title,
        tour: {
          team: teamId,
          date: date,
          productId: productId,
        },
        reservation: {
          id: reservation.id,
          name: reservation.clientName,
          agencyCode: reservation.agencyCode
        }
      }

      const chatMeta = await callFunction('recallComprehensiveTourChat', chatParams);
      navigate(`/chat/rooms/${chatMeta.id}`, {state: {chatMeta, reservation}});

    } catch (e) {
      console.log(e);
      alert('참석이 어렵습니다.');
    }
    setLoadingChat(false);
  }

  console.log(updates);
  console.log(reservation);
  console.log(pickups);


  return (
      <ListItem
          sx={theme => ({
            p: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'stretch',
            borderBottom: `solid 1px ${theme.palette.divider}`
          })}
      >
        <ListItemButton
            sx={{
              py: 0.5,
              px: 2,
              backgroundColor: reservation.agency === 'KTOS' ? '#e7e7ff' : '#f9f9fd',
            }}
            disableRipple
            disableTouchRipple
            onClick={handleToggleOpen}
        >
          <ListItemIcon
              sx={{
                minWidth: 0
              }}
              onClick={stopPropagation}
          >
            <Checkbox
                edge="start"
                disabled={!isCheckable}
                defaultChecked={reservation.check}
                // checked={checked}

                onChange={handleLiveChecked}
                disableRipple
            />
          </ListItemIcon>
          <ListItemText
              sx={{
                marginLeft: 0.5
              }}
              primaryTypographyProps={
                {component: 'div'}
              }
              secondaryTypographyProps={
                {component: 'div'}
              }
              primary={
                <Box display={'flex'}
                     justifyContent={'space-between'}
                     alignItems={'center'}
                     marginBottom={0.5}
                >
                  <Typography
                      fontWeight={'500'}
                      variant={'subtitle1'}
                      color={noShow ? 'grey.400' : 'default'}
                  >
                    {reservation.clientName.replace(/\([^)]*\)/g, "")} ({reservation.people})
                  </Typography>
                  <Chip size={'small'}
                        avatar={<Avatar>{reservation.agency}</Avatar>}
                        label={
                          <Typography
                              sx={{maxWidth: '120px'}}
                              variant={'subtitle2'}
                              overflow={'hidden'}
                              textOverflow={'ellipsis'}
                              color={noShow ? 'grey.400' : 'default'}
                          >
                            {reservation.agencyCode}
                          </Typography>
                        }
                        color={'primary'}
                        variant={'outlined'}
                        sx={{
                          flexDirection: 'row-reverse',
                          border: 'none',
                          color: 'inherit',
                          fontWeight: '500'
                        }}
                  />
                </Box>
              }
              secondary={
                <Grid container spacing={0.5}>
                  <Grid item xs={6}>
                    <Typography
                        component={'p'}
                        variant={'subtitle2'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                        fontWeight={'500'}
                        color={noShow ? 'grey.400' : 'default'}
                    >
                      {`Ad: ${reservation.adult} `}
                      {`Kd: ${reservation.kid} `}
                      {`Bb: ${reservation.infant} `}
                    </Typography>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography
                        component={'p'}
                        variant={'subtitle2'}
                        textAlign={'right'}
                        whiteSpace={'nowrap'}
                        color={noShow ? 'grey.400' : 'default'}
                    >
                      <Typography
                          component={'span'}
                          variant={'subtitle2'}
                          textOverflow={'ellipsis'}
                          overflow={'hidden'}
                          whiteSpace={'nowrap'}
                          mx={1}
                      >
                        {reservation.nationality}
                      </Typography>
                      <Box
                          component={'span'}
                          display={'inline-block'}
                          width={'18px'}
                          height={'18px'}
                      >
                        {reservation.nationalityFlag}
                      </Box>
                    </Typography>

                  </Grid>
                  {/*<Grid item xs={6}>*/}
                  {/*    <Typography*/}
                  {/*        component={'p'}*/}
                  {/*        variant={'caption'}*/}
                  {/*        textOverflow={'ellipsis'}*/}
                  {/*        overflow={'hidden'}*/}
                  {/*        whiteSpace={'nowrap'}*/}
                  {/*        color={noShow ? 'grey.400' : 'default'}*/}
                  {/*    >*/}
                  {/*        {reservation.tel} <br/>*/}
                  {/*    </Typography>*/}
                  {/*</Grid>*/}
                  {/*<Grid item xs={6}>*/}
                  {/*    <Typography*/}
                  {/*        component={'p'}*/}
                  {/*        variant={'caption'}*/}
                  {/*        textAlign={'right'}*/}
                  {/*        textOverflow={'ellipsis'}*/}
                  {/*        overflow={'hidden'}*/}
                  {/*        whiteSpace={'nowrap'}*/}
                  {/*        color={noShow ? 'grey.400' : 'default'}*/}
                  {/*    >*/}
                  {/*        {reservation.email} <br/>*/}
                  {/*    </Typography>*/}
                  {/*</Grid>*/}
                  {/*{*/}
                  {/*    reservation.messenger && reservation.messenger.length > 5*/}
                  {/*        ? (*/}
                  {/*            <Grid item xs={12}>*/}
                  {/*                <Typography*/}
                  {/*                    component={'p'}*/}
                  {/*                    variant={'caption'}*/}
                  {/*                    textOverflow={'ellipsis'}*/}
                  {/*                    overflow={'hidden'}*/}
                  {/*                    whiteSpace={'nowrap'}*/}
                  {/*                >*/}
                  {/*                    {reservation.messenger} <br/>*/}
                  {/*                </Typography>*/}
                  {/*            </Grid>*/}
                  {/*        )*/}
                  {/*        : null*/}
                  {/*}*/}
                  <Grid item xs={10}>
                    {
                      (reservation.option ?? []).map((option) => (
                          <Stack flexDirection={'row'} gap={1}>
                            <Typography
                                component={'p'}
                                variant={'subtitle2'}
                                color={'primary'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                            >
                              {option.option}
                            </Typography>
                            <Typography
                                component={'p'}
                                variant={'subtitle2'}
                                color={'primary'}
                                textOverflow={'ellipsis'}
                                overflow={'hidden'}
                                whiteSpace={'nowrap'}
                                textAlign={'end'}
                            >
                              {option.people}
                            </Typography>
                          </Stack>
                      ))
                    }
                  </Grid>
                  <Grid item xs={2}>
                    <Stack
                        flexDirection={'row-reverse'}
                        gap={1}
                    >

                      {
                        reservation.language.split(',').map((l) => l.trim()).map((language) => (

                                <Box
                                    backgroundColor={
                                      language === 'ENGLISH'
                                          ? '#002868'
                                          : language === 'CHINESE'
                                              ? '#FF4E20'
                                              : '#1ba242'
                                    }
                                    color={'white'}
                                    sx={{
                                      opacity: noShow ? 0.15 : 1,
                                      px: 0.5,
                                      borderRadius: 1
                                    }}
                                >
                                  <Typography
                                      component={'p'}
                                      variant={'subtitle2'}
                                      textOverflow={'ellipsis'}
                                      overflow={'hidden'}
                                      whiteSpace={'nowrap'}
                                      textAlign={'right'}
                                      color={'inherit'}
                                  >
                                    {
                                      language === 'ENGLISH'
                                          ? 'EN'
                                          : language === 'CHINESE'
                                              ?
                                              'CN'
                                              : language === 'JAPANESE'
                                                  ? 'JP'
                                                  : language === 'KOREAN'
                                                      ?
                                                      'KO'
                                                      : language

                                    }
                                  </Typography>
                                </Box>
                            )
                        )}

                      <Box>
                        <Typography
                            component={'p'}
                            variant={'subtitle2'}
                            textOverflow={'ellipsis'}
                            overflow={'hidden'}
                            whiteSpace={'nowrap'}
                            textAlign={'right'}
                            color={noShow ? 'grey.400' : 'secondary.main'}
                        >
                          {reservation.stroller ? '유모차' : ''}
                        </Typography>
                      </Box>

                    </Stack>

                  </Grid>
                  <Grid item xs={12}>
                    <Typography
                        component={'p'}
                        variant={'subtitle2'}
                        color={'secondary'}
                        textOverflow={'ellipsis'}
                        overflow={'hidden'}
                        whiteSpace={'nowrap'}
                    >
                      {reservation.memo}
                    </Typography>
                  </Grid>
                </Grid>
              }
          />
        </ListItemButton>
        <Collapse
            unmountOnExit
            in={open && guide.level <= 2 /*driver는 이용 불가*/}
            timeout={100}
        >
          <Box
              sx={{
                paddingTop: 0.5,
                paddingBottom: 1,
                px: 2,
                backgroundColor: '#f9f9fd',
                textAlign: 'center'
              }}
          >
            <Button
                fullWidth
                color={'primary'}
                startIcon={<QrIcon/>}
                variant={'outlined'}
                onClick={handleClickQR}
                sx={{
                  marginBottom: 1.5
                }}
            >
              TOUR CHAT (QR,LINK)
            </Button>
            {
              qr ?
                  <Box
                      sx={{py: 2, display: 'flex', flexDirection: 'column', gap: 1, alignItems: 'center'}}
                  >
                    <Box
                        ref={qrRef}
                        sx={{
                          width: '100%',
                          display: 'inline-flex',
                          flexDirection: 'column',
                          gap: 2,
                          alignItems: 'center',
                          backgroundColor: 'white',
                          p: 3,
                        }}
                    >
                      <QRCode
                          value={
                            reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                                `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                          }/>
                      <Typography fontWeight={"bold"}>
                        {reservation.clientName.replace(/\([^)]*\)/g, "")}<br/>
                        {reservation.agencyCode}
                      </Typography>
                    </Box>
                    <Box
                        width={'100%'}
                        px={1}
                    >
                      <TextField fullWidth disabled variant={'standard'}
                                 value={
                                   reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                                       `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                       : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                 }/>
                    </Box>
                    <Box
                        sx={{
                          display: 'flex',
                          flexDirection: 'row',
                          gap: 2,
                          alignItems: 'center',
                          justifyContent: 'center'
                        }}
                    >
                      <Button variant={'text'} onClick={downloadQR}>
                        DOWNLOAD QR
                      </Button>
                      <Button onClick={() => {
                        copy(
                            reservation.kup || productId?.toLowerCase()?.includes('seoul') ?
                                `https://ktourstory.kup.travel/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`
                                : `https://tour.ktourstory.com/reservation?id=${reservation.id}&agencyCode=${reservation.agencyCode}`)
                      }}>
                        COPY LINK
                      </Button>
                    </Box>
                  </Box>
                  : null
            }

            {/*<Button*/}
            {/*    fullWidth*/}
            {/*    color={'primary'}*/}
            {/*    startIcon={loadingChat ? <CircularProgress size={24}/> : <ChatIcon/>}*/}
            {/*    variant={'outlined'}*/}
            {/*    onClick={handleChatDirect}*/}
            {/*    sx={{*/}
            {/*      marginBottom: 1.5*/}
            {/*    }}*/}
            {/*>*/}
            {/*  CHAT DIRECT*/}
            {/*</Button>*/}
            <Button
                disabled={(reservation.tel?.length ?? 0) < 5}
                fullWidth
                component={'a'}
                href={`tel:${reservation.tel?.replaceAll(/[^\d+-]*/gi, '')}`}
                color={'primary'}
                startIcon={<PhoneIcon/>}
                variant={'outlined'}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {reservation.tel?.replaceAll(/[^\d+-]*/gi, '')}
            </Button>
            <Button
                fullWidth
                disabled={(reservation.email?.length ?? 0) < 5}
                component={'a'}
                href={`mailto:${reservation.email}?subject="Contact From Today's Tour"&body="Hello!"}`}
                color={'secondary'}
                startIcon={<EmailIcon/>}
                variant={'outlined'}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {reservation.email}
            </Button>
            <Button
                fullWidth
                disabled={(reservation.messenger?.length ?? 0) < 5}
                color={'secondary'}
                startIcon={<ChatIcon/>}
                variant={'outlined'}
                onClick={handleClickMessenger}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {reservation.messenger}
            </Button>
            <Button
                fullWidth
                disabled={!isControlable}
                color={'error'}
                variant={'outlined'}
                onClick={handleToggleNoShow}
                sx={{
                  marginBottom: 1.5
                }}
            >
              {noShow ? 'Check In' : 'No Show'}
            </Button>
            {
              isDeletable
                  ? (
                      <Button
                          fullWidth
                          disabled={!isDeletable || isCanceling}
                          color={'error'}
                          variant={'outlined'}
                          onClick={handleDelete}
                          sx={{
                            marginBottom: 1.5
                          }}
                      >
                        {
                          isCanceling ? <CircularProgress size={24}/>
                              : 'Cancel(Delete)'
                        }
                      </Button>
                  )
                  : null
            }

            {
              !isEditable
                  ? (<Collapse
                      unmountOnExit
                      in={expand}
                      timeout={100}
                  >
                    <Box
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 2,
                          backgroundColor: '#f9f9fd',
                          textAlign: 'left'
                        }}
                    >
                      <Grid container>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(`${reservation.clientName}(${reservation.people})`)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5,
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.clientName}({reservation.people})
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.agencyCode)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.agencyCode}({reservation.agency})
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.tel)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Box>
                              <Typography
                                  variant={'body2'}
                                  whiteSpace={'pre-wrap'}
                                  component={'span'}
                              >
                                {reservation.tel}
                              </Typography>
                            </Box>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.messenger)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.messenger}
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        <Grid item xs={12}>
                          <ButtonBase
                              onClick={handleCopyGen(reservation.email)}
                              sx={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'space-between',
                                alignItems: 'center',
                                py: 0.5
                              }}
                          >
                            <Typography
                                variant={'body2'}
                                whiteSpace={'pre-wrap'}
                                component={'span'}
                            >
                              {reservation.email}
                            </Typography>
                            <CopyIcon
                                sx={{
                                  width: '18px',
                                  height: '18px'
                                }}
                            />
                          </ButtonBase>
                        </Grid>
                        {
                          reservation.memo
                              ? (<Grid item xs={12}>
                                    <ButtonBase
                                        onClick={handleCopyGen(reservation.memo)}
                                        sx={{
                                          width: '100%',
                                          display: 'flex',
                                          justifyContent: 'space-between',
                                          alignItems: 'center',
                                          py: 0.5
                                        }}
                                    >
                                      <Typography
                                          variant={'body2'}
                                          whiteSpace={'pre-wrap'}
                                          component={'span'}
                                      >
                                        {reservation.memo}
                                      </Typography>
                                      <CopyIcon
                                          sx={{
                                            width: '18px',
                                            height: '18px'
                                          }}
                                      />
                                    </ButtonBase>
                                  </Grid>
                              ) : null
                        }
                      </Grid>
                    </Box>
                  </Collapse>)
                  : (<Collapse
                      unmountOnExit
                      in={expand}
                      timeout={100}
                  >
                    <Box
                        sx={{
                          paddingTop: 1,
                          paddingBottom: 2,
                          backgroundColor: '#f9f9fd',
                          textAlign: 'left'
                        }}
                    >
                      <Grid container spacing={2}>
                        <Grid item xs={2}>
                          <TextField
                              fullWidth
                              disabled
                              size={'small'}
                              label={'Agency'}
                              name={'agency'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={reservation.agency}
                          />
                        </Grid>
                        <Grid item xs={10}>
                          <TextField
                              fullWidth
                              disabled
                              size={'small'}
                              label={'AgencyCode'}
                              name={'agencyCode'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={reservation.agencyCode}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              disabled
                              size={'small'}
                              label={'Product'}
                              name={'Product'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={product?.name}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>
                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              select
                              size={'small'}
                              label={'Pickup Place'}
                              name={'pickupPlace'}
                              InputLabelProps={{shrink: true}}
                              SelectProps={{
                                native: true
                              }}
                              value={updates?.pickupPlace ?? reservation.pickupPlace}
                              onChange={handleUpdateReservation}
                          >
                            {
                              (pickups ?? []).map((p) => (
                                  <option key={p.place} value={p.place}>
                                    {p.place}
                                  </option>
                              ))
                            }
                          </TextField>
                        </Grid>

                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Name'}
                              type={'string'}
                              name={'clientName'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.clientName ?? reservation.clientName}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>
                        <Grid item xs={6}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Adult'}
                              type={'number'}
                              name={'adult'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={(updates?.adult ?? reservation.adult) || ''}
                              onChange={handleUpdateReservation} a
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Kid'}
                              type={'number'}
                              name={'kid'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={(updates?.kid ?? reservation.kid) || ''}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Infant'}
                              type={'number'}
                              name={'infant'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={(updates?.infant ?? reservation.infant) || ''}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Tel'}
                              type={'string'}
                              name={'tel'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.tel ?? reservation.tel}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Email'}
                              type={'string'}
                              name={'email'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.email ?? reservation.email}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              size={'small'}
                              label={'Messenger'}
                              type={'string'}
                              name={'messenger'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.messenger ?? reservation.messenger}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>


                        <Grid item xs={12}>
                          <Divider/>
                        </Grid>

                        <Grid item xs={12}>
                          <TextField
                              fullWidth
                              multiline
                              size={'small'}
                              label={'Memo'}
                              type={'string'}
                              name={'memo'}
                              InputLabelProps={{
                                shrink: true
                              }}
                              value={updates?.memo ?? reservation.memo}
                              onChange={handleUpdateReservation}
                          />
                        </Grid>


                        <Grid item xs={12}>
                          <Button
                              fullWidth
                              variant={'contained'}
                              color={'primary'}
                              disabled={isUpdating || Object.values(updates).filter(v => v !== undefined).length === 0}
                              onClick={handleSaveUpdates}>
                            Save Reservation
                            {
                                isUpdating &&
                                <CircularProgress size={'small'}/>
                            }
                          </Button>
                        </Grid>

                        <Grid item xs={12}>
                          <Button
                              fullWidth
                              variant={'contained'}
                              color={'error'}
                              disabled={isUpdating}
                              onClick={handleCancel}>
                            Cancel Reservation
                            {
                                isUpdating &&
                                <CircularProgress size={'small'}/>
                            }
                          </Button>
                        </Grid>
                      </Grid>
                    </Box>
                  </Collapse>)
            }

            <ButtonBase
                onClick={handleToggleExpand}
                sx={{
                  width: '100%',
                  py: 0,
                  transform: `rotate(${expand ? 180 : 360}deg)`
                }}
            >
              <ExpandMoreIcon/>
            </ButtonBase>
          </Box>
        </Collapse>


      </ListItem>
  );
}